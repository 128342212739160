export const useModalStore = defineStore('modal', {
    state: () => ({
        modalsStack: []
    }),

    actions: {
        registerModal(modalId) {
            this.modalsStack.push(modalId);
        },

        unregisterModal(modalId) {
            this.modalsStack = this.modalsStack.filter(id => id !== modalId);
        },
    },

    getters: {
        isTopModal: (state) => (modalId) => {
            return state.modalsStack[state.modalsStack.length - 1] === modalId;
        }
    }
})